import React from "react"
import Layout from "../layouts/layout"
import { graphql, useStaticQuery, Link } from "gatsby"
import Head from "../components/head"

import classes from "./blog.module.scss"

function Blog() {
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
        edges {
          node {
            frontmatter {
              title
              date(formatString: "Do MMMM, YYYY")
              category
              featured_image {
                publicURL
              }
            }
            excerpt
            timeToRead
            html
            excerpt
            fields {
              slug
            }
          }
        }
      }
    }
  `)

  // const data = useStaticQuery(graphql`
  //   query {
  //     allContentfulBlogPost(sort: { fields: publishedDate, order: DESC }) {
  //       edges {
  //         node {
  //           title
  //           slug
  //           publishedDate(formatString: "Do MMMM, YYYY")
  //           featuredImage {
  //             file {
  //               url
  //             }
  //           }
  //         }
  //       }
  //     }
  //   }
  // `)
  return (
    <Layout>
      <Head title="Blog" />
      <h1>&lt;Blog /&gt;</h1>
      <p>The best is yet to come.</p>
      <ol className={classes.posts}>
        {data.allMarkdownRemark.edges.map((edge, index) => (
          <li key={index} className={classes.post}>
            <Link to={`${edge.node.fields.slug}`}>
              <img
                src={edge.node.frontmatter.featured_image.publicURL}
                alt=""
              />
              <h2>{edge.node.frontmatter.title}</h2>
              <p>
                {edge.node.frontmatter.category.map((c, i) => (
                  <span className={classes.category} key={i}>
                    #{c}
                  </span>
                ))}
              </p>
              <p className={classes.date}>{edge.node.frontmatter.date}</p>
              <p>{edge.node.excerpt}</p>
            </Link>
          </li>
        ))}
      </ol>
    </Layout>
  )
}

export default Blog
